<template>
  <div>
      <v-card style="border-radius: 15px;" id="modalGiftCode">
        <v-card-text class="pt-5">
          <div class="titleModal">
            {{ $t('giftCode.title') }}
          </div>
          <div class="mt-10 formArea">
            <v-form ref="giftCodeForm" @keypress.enter="submitGiftCode" @submit.prevent="submitGiftCode">
              <v-text-field id="giftCodeTextFiled" v-model="giftCode" :rules="requiredRules" dense
                            :label="$t('giftCode.placeholder')"></v-text-field>
            </v-form>
          </div>
          <div class="actionArea mt-5 text-center">
            <v-btn :disabled="loadingStatus" class="confirmBtn white--text" id="submitGiftCode" color="#46b1a1"
                   elevation="0"
                   @click="submitGiftCode">
              {{$t('confirm')}}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

  </div>
</template>

<script>
export default {
  props: {
    modalGiftCode: Object
  },
  data() {
    return {
      giftCode: null,
    }
  },
  methods: {
    submitGiftCode() {
      if (this.$refs.giftCodeForm.validate()) {
        this.$emitEvent('setLoading', true)
        this.requesting('wallet', 'submitGiftCode', '', {
          giftCode: this.giftCode
        }).then(() => {
          this.requesting('auth', 'getProfile').then(() => {
            this.$emit('afterSubmitCode')
          }).finally(() => {
            this.$emitEvent('setLoading', false)
          })
        }).catch(() => {
          this.$emitEvent('setLoading', false)
        }).finally(() => {
          this.$refs.giftCodeForm.reset()
        })
      }
    }
  }
}
</script>

<style scoped>

</style>