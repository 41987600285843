<template>
  <div>
    <div class="sessionsHistory" v-for="(item , i) in history" :key="i">
      <div class="history d-flex justify-space-between">
        <div class="packageInfo">
          <div class="packageName">
            {{ item.cycle.heading }}
          </div>
          <div class="doctorName">
            {{ item.doctor.full_name }}
          </div>
        </div>
        <div class="dateSessions en_num">
          {{ filteringTimeTexted(item.fixed_date_time_g) }} {{ item.duration }}:00
        </div>
      </div>
      <div class="footer d-flex justify-space-between mt-4">
        <div class="sessionReminded mt-2">
        </div>
        <div class="downloadArea">
          <v-btn color="#e6f2f2" small class="downloadBtn" elevation="0" @click="downloadFactor(item.id)">
            دانلود فاکتور
          </v-btn>
        </div>
      </div>
    </div>
    svsdv
    <div class="paginationArea">
      <v-pagination v-model="pagination.page" :length="pagination.total" class="mt-6" circle></v-pagination>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import DateMixins from "@/mixins/DateMixins";

export default {
  mixins:[DateMixins],
  data() {
    return {
      pagination: {
        page: 1,
        total: 1
      },
      history: null,
    }
  },
  methods: {
    getSessionHistory() {
      this.$emitEvent('setLoading', true)
      this.requesting('wallet', 'getSessionHistory', {
        page: this.pagination.page
      }).then((resp) => {
        this.toTop()
        this.history = resp.data.sessions
        this.pagination = {
          total: resp.data.pagination.total_pages,
          page: resp.data.pagination.current_page
        }
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    downloadFactor(id) {
      this.requesting('factor', 'getWalletUser', {id: id}, {type: "pdf"}).then((resp) => {
        // console.log(resp)
      })
    }
  },
  mounted() {
    this.getSessionHistory()
  },
  watch: {
    'pagination.page'() {
      this.getSessionHistory()
    }
  }
}
</script>

<style scoped>
.sessionsHistory {
  border-bottom: 1px solid #dbdbdb;
  padding: 20px;
}

.packageName {
  font-size: 14px;
  font-weight: bolder;
}

.doctorName {
  font-size: 10px;
}

.dateSessions {
  font-size: 14px;
}

.sessionReminded {
  font-size: 12px;
}

.downloadBtn {
  border-radius: 5px;
  color: #46b1a1 !important;
}
</style>
