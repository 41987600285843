<template>
  <div class="walletArea ">
    <div :class="$vuetify.rtl ? 'text-right' : 'text-left' " class="titleProfileSection " :dir="$vuetify.rtl ? 'ltr' : 'rtl'">
      {{ $t('section_title.gift_code') }}
      <v-icon small color="#4597DF">mdi-circle</v-icon>
    </div>
    <div class="AddGiftCode" :class="$vuetify.rtl ? 'AddGiftCodeRtl' : 'AddGiftCodeLtr' ">
      <div class="addFormModalContent">
        <FormgiftCode @afterSubmitCode="afterSubmitCode" :modal-gift-code="modalGiftCode"/>
      </div>
    </div>

    <div :class="$vuetify.rtl ? 'text-right' : 'text-left' " class="titleProfileSection mt-10" :dir="$vuetify.rtl ? 'ltr' : 'rtl'">
      {{ $t('section_title.wallet') }}
      <v-icon small color="#4597DF">mdi-circle</v-icon>
    </div>
    <div class="balanceArea  d-flex justify-space-between flex-row-reverse">
      <div class="en_num">
        {{ withCurrency(profile.affiliate_wallet[profile.currency].balance, profile.currency) }}
      </div>
      <div class="titleBalance">
        {{ $t('wallet.balance') }}
      </div>
    </div>

    <div class="transactionArea" id="transition">
      <div class="transaction" v-for="(transition , i) in walletRecords" :key="i">
        <div class="d-flex justify-space-between">
          <div>
            <div class="titleTransaction">
              {{ $t(`wallet.${transition.type}`) }}
            </div>
            <div class="dateTransaction en_num ">
              {{ filterTransactionDate(transition.date, transition.time) }}
            </div>
          </div>
          <div class="en_num d-flex  align-center" :class="transition.type == 'charge' && 'primary--text'">
            {{ withCurrency(transition.amount, transition.currency) }}
          </div>
        </div>
      </div>
    </div>
    <div class="paginationArea" v-if="pagination.total != 1">
      <v-pagination v-model="pagination.page" :dir="$vuetify.rtl ? 'rtl' : 'lrt'" :length="pagination.total" class="mt-6" circle></v-pagination>
    </div>
    <!--    <div class="addGiftCodeArea text-center">-->
    <!--      <v-btn-->
    <!--          @click="modalGiftCode.visibility  = !modalGiftCode.visibility"-->
    <!--          class="openModalGiftCode white&#45;&#45;text"-->
    <!--          elevation="0"-->
    <!--          id="openModalGiftCode"-->
    <!--          color="#46b1a1">-->
    <!--        {{ $t('dashboard.wallet.subTitle') }}-->
    <!--      </v-btn>-->
    <!--    </div>-->
  </div>
</template>

<script>
/*eslint-disable*/

import {mapGetters} from "vuex";
import moment from "moment-jalaali";
import ModalGiftCode from "@/components/GiftCode/ModalGiftCode";
import FormgiftCode from "@/components/GiftCode/FormgiftCode";

export default {
  components: {FormgiftCode, ModalGiftCode},
  data() {
    return {
      walletRecords: null,
      modalGiftCode: {
        visibility: false
      },
      pagination: {
        page: 1,
        total: 1
      }
    }
  },
  methods: {
    getWalletRecord() {
      this.$emitEvent('setLoading', true)
      this.requesting('wallet', 'getWalletUser', {
        page: this.pagination.page
      }).then((resp) => {
        this.toTop()
        this.walletRecords = resp.data.records
        this.pagination = {
          total: resp.data.pagination.total_pages,
          page: resp.data.pagination.current_page
        }
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    filterTransactionDate(date, time) {
      return moment(date + " " + time).format('YYYY/MM/DD hh:mm')
    },
    afterSubmitCode() {
      this.modalGiftCode = false
      this.pagination = {
        page: 1,
        total: 1
      }
      this.$emitEvent('notify', {title: 'کد هدیه اعمال شد', color: 'green', id: 'success_notify'})
      this.getWalletRecord();
    }
  },
  mounted() {
    this.getWalletRecord()
  },
  watch: {
    'pagination.page'() {
      this.getWalletRecord()
    }
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile'
    }),
  },
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .addFormModalContent {
    width: 100% !important;
  }
}

.openModalGiftCode {
  width: 178px;
  height: 45px;
  margin: 19px 5px 4px;
  padding: 1px 15px 10px;
  border-radius: 10px;
  background-color: rgb(70, 177, 161);
}

.balanceArea {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid black;
  color: black;
}

.transaction {
  height: 110px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  border-bottom: 2px solid #dbdbdb;
}

.paginationArea {
  width: 80%;
  margin: 0px auto;
}

.titleTransaction {
  font-size: 17px;
}

.dateTransaction {
  font-size: 12px;
  margin-top: 5px;
}

.giftCodeModal {
  width: 452px;
  height: 266px;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: rgb(255, 255, 255);
}

.AddGiftCode {
  display: flex;
  margin: 10px 0px 10px 0px;
  /*width: 250px !important;*/
}

.AddGiftCodeRtl {
  direction: rtl;
}

.AddGiftCodeLtr {
  direction: ltr;
}


.addFormModalContent {
  width: 300px;
  /*margin: 0px auto;*/
}
</style>