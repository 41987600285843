<template>
  <div>
    <!--    <SegmentController :segments="segments"/>-->
    <!--    <div class="walletArea  mt-7">-->
    <!--      <v-window v-model="segments.selected" dir="ltr" touchless>-->
    <!--        <v-window-item value="factors">-->
    <div class="walletArea" dir="ltr">
      <Factors/>
    </div>
    <!--        </v-window-item>-->
    <!--        <v-window-item value="wallet">-->
    <!--          <BalanceWallet/>-->
    <!--        </v-window-item>-->
    <!--      </v-window>-->
  </div>
</template>

<script>

import SessionHistory from "@/components/Wallet/SessionHistory";
import BalanceWallet from "@/components/Wallet/balanceWallet";
import SegmentController from "@/components/Main/SegmentController";
import Factors from "@/components/Factor/Factors";
/*eslint-disable*/
export default {
  components: {Factors, SegmentController, BalanceWallet, SessionHistory},
  data() {
    return {
      segments: {
        items: [
          {
            title: 'فاکتور',
            key: 'factors',
          },
          {
            title: 'کیف‌پول',
            key: 'wallet',
          },
        ],
        selected: "wallet ",
      }
    }
  },
  methods: {},


}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .segmentController {
    width: 55% !important;
  }

  .walletArea {
    width: 90% !important;
  }
}


.walletArea {
  width: 70%;
  margin: 20px auto;
}


</style>