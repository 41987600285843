<template>
  <div>
    <div class="factorArea " :class="$vuetify.breakpoint.lgAndUp && 'pa-2'">
      <div v-if="!factors || factors.length == 0" style="overflow: hidden">
        <div style=";position: relative;z-index: 3">
          <div class="text-center">
            <img src="../../assets/EmptyState.svg" width="200" alt="">
          </div>
          <div class="titleSessionRequestBlack text-center" style="margin-top: 0px;direction: rtl">
            فاکتوری برای شما ثبت نشده.
          </div>
          <div class="text-center mt-8">
            <v-btn color="#3CBE8B" :to="{name:'packages'}" x-small @click="sessionWindow = 2" large elevation="0"
                   class="white--text font-weight-bold">
              خرید جلسه
            </v-btn>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="factorItem " v-for="(factor , i) in factors" :key="i"
             :class="openedFactor == factor.id && 'openFactor'">
          <div class="factorRow  justify-center flex-column" :id='factor.id'
               @click="openedFactor = factor.id">
            <div class="d-flex flex-row-reverse justify-space-between"
                 :class="$vuetify.breakpoint.lgAndUp && 'flex-wrap'">
              <div>
                <div class="titleFactor">
                  <template v-if="factor.packages.length == 1">
                    <template v-if="$vuetify.rtl">
                      {{ factor.packages[0].title }}
                    </template>
                    <template v-else>
                      {{ factor.packages[0].en_title }}
                    </template>
                  </template>
                  <template v-else>
                    {{ factor.packages.length }}
                    {{ $t('product') }}
                  </template>
                  - <span class="state">
                {{ factorState(factor) }}
              </span>
                </div>
                <div class="dateFactor mt-6" v-if="$vuetify.breakpoint.lg">
                  {{ factor.submitted_at  | dataSettingFilter }}
                  <br>
                  <span dir="ltr">
                  {{ dataSettingFilterJalali(factor.submitted_at) }}
                </span>
                </div>
              </div>
              <div :class="$vuetify.breakpoint.lg && 'flex-grow-1 d-flex justify-space-between mt-4'">
                <div style="font-size: 12px" v-if="$vuetify.breakpoint.md">
                  {{ factor.submitted_at  | dataSettingFilter }}
                  <br>
                  {{ dataSettingFilterJalali(factor.submitted_at) }}
                </div>
                <div class="amountFactor text-left">
                  {{ withCurrency(factor.price, factor.currency_code, 'long') }}
                  <br>
                  <div class="actionFactor mt-4 text-left " v-if="$vuetify.breakpoint.lgAndUp">

                    <v-fade-transition mode="out-in">
                      <v-btn text color="#46b1a1" class="moreInfoBtn "
                             v-if="openedFactor != factor.id"
                             @click.stop="openedFactor != factor.id ? openedFactor = factor.id : openedFactor = null">
                        {{ $t('details') }}
                        <v-fab-transition>
                <span v-if="openedFactor != factor.id">
                >
              </span>
                          <span style="transform: rotate(-90deg)" class="mr-1" v-else>
                          >
              </span>
                        </v-fab-transition>
                      </v-btn>
                      <template v-if="openedFactor == factor.id">
                        <div class="d-flex">
                          <div>
                            <v-btn v-if="factor.canBePaid" class="loadFactorBtn  white--text" color="#46b1a1"
                                   elevation="0"
                                   :to="{name:'factor',params:{factorID:factor.id}}">
                              {{ $t('factor') }}
                            </v-btn>
                          </div>
                          <div>
                            <v-btn text color="#46b1a1" class="loadFactorBtn ml-3" @click.stop="openedFactor = null">
                              {{ $t('close') }}
                              <span style="transform: rotate(-90deg)" class="mx-2">
                          >
                        </span>
                            </v-btn>
                          </div>
                        </div>
                      </template>
                    </v-fade-transition>
                  </div>

                </div>

              </div>
            </div>
            <div class="actionFactor mt-4 d-flex justify-space-between" v-if="$vuetify.breakpoint.mdAndDown">
              <div>
                <v-fade-transition>
                  <v-btn class="loadFactorBtn  white--text" color="#46b1a1" elevation="0"
                         v-if="openedFactor == factor.id && factor.canBePaid"
                         :to="{name:'factor',params:{factorID:factor.id}}">
                    {{ $t('factor') }}
                  </v-btn>
                </v-fade-transition>
              </div>
              <v-btn text color="#46b1a1" class="moreInfoBtn"
                     @click.stop="openedFactor != factor.id ? openedFactor = factor.id : openedFactor = null">
                {{ $t('details') }}
                <v-fab-transition>
                <span v-if="openedFactor != factor.id">
                >
              </span>
                  <span style="transform: rotate(-90deg)" class="mr-1" v-else>
                          >
              </span>
                </v-fab-transition>
              </v-btn>
            </div>
          </div>
          <div class="factorMoreInfo" v-if="openedFactor == factor.id">
            <div class="productsFactorArea">
              <div class="titleMoreInfoItem">
                {{ $t('packageFactor') }}
              </div>
              <div class="ProductsFactor pb-5 mt-7 " :class="$vuetify.breakpoint.lgAndUp && 'px-4'">
                <v-row class="productFactor" :class="$vuetify.breakpoint.lgAndUp && 'px-3'"
                       v-for="(item , i) in factor.packages" :key="i">
                  <v-col cols="2" lg="3">
                    <div class="amountPackage  text-left pl-4">
                      {{ withCurrency(item.pivot.final_price, factor.currency_code, 'long') }}
                    </div>
                  </v-col>
                  <v-col cols="2" lg="4">
                    <div class="quantityProduct">
                      ×
                      {{ item.pivot.quantity }}
                    </div>
                  </v-col>
                  <v-col cols="8" lg="5">
                    <div class="titleProduct">
                      <template v-if="$vuetify.rtl">
                        {{ item.title }}
                      </template>
                      <template v-else>
                        {{ item.en_title }}
                      </template>
                    </div>
                  </v-col>

                </v-row>
              </div>
            </div>
            <div class="paymentFactorArea">
              <div class="titleMoreInfoItem">
                {{ $t('paymentDetail') }}
              </div>
            </div>
            <div class="paymentsArea px-6">
              <v-data-table
                  :headers="headers"
                  :items="factor.payments"
                  hide-default-footer
                  disable-pagination
                  dir="rtl"
              >
                <template v-slot:item.date="{item}">
                  <p style="font-size: 12px" class="mt-5">
                    {{ item.date | dataSettingFilter }}
                    <br>
                    {{ dataSettingFilterJalali(factor.submitted_at) }}

                  </p>
                </template>
                <template v-slot:item.type="{item}">
                  <p style="font-size: 12px" class="mt-5">
                    {{ factorPaymentType(item) }}
                  </p>
                </template>
                <template v-slot:item.state="{item}">
                  {{ factorPaymentState(item) }}
                </template>
                <template v-slot:item.amount="{item}">
                  {{ withCurrency(item.final_amount, item.currency, 'long') }}
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
        <v-pagination v-if="pagination.total != 1" v-model="pagination.page" :length="pagination.total"
                      dir="rtl"></v-pagination>
      </template>


    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import DateMixins from "@/mixins/DateMixins";

export default {
  mixins: [DateMixins],
  data() {
    return {
      pagination: {
        page: 1,
        total: 1
      },
      openedFactor: null,
      factors: null,
      headers: [
        {
          text: this.$t('factors.datatable.date'),
          sortable: false,
          value: 'date',
        },
        {text: this.$t('factors.datatable.type'), sortable: false, value: 'type'},
        {text: this.$t('factors.datatable.state'), sortable: false, value: 'state'},
        {text: this.$t('factors.datatable.amount'), sortable: false, value: 'amount'},
      ],
    }
  },
  methods: {
    getUserFactors() {
      this.$emitEvent('setLoading', true)
      this.requesting('factor', 'getUserFactors', {page: this.pagination.page}).then((resp) => {
        this.factors = resp.data.factors
        this.pagination = {
          page: resp.data.pagination.current_page,
          total: resp.data.pagination.total_pages
        }
        this.toTop()
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    }
  },
  mounted() {
    this.getUserFactors()
  },
  watch: {
    'pagination.page'() {
      this.getUserFactors()
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .amountFactor {
    font-size: 15px !important;
    width: 80px;
  }

  .factorRow {
    padding: 20px 5px 5px 5px !important;
  }

  .titleProduct {
    font-size: 12px !important;
  }

  .amountPackage {
    font-size: 12px !important;
  }
}

.factorRow {
  min-height: 100px;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  padding: 20px 20px 20px 25px;

}

.factorItem {
  transition: all 0.3s;
  background: white;
  cursor: pointer;
}

.titleFactor {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: rgb(0, 0, 0);
}

.dateFactor {
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgb(0, 0, 0);
}

.amountFactor {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: rgb(0, 0, 0);
}

.amountPackage {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: rgb(0, 0, 0);
}

.moreInfoBtn {
  font-size: 15px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: rgb(70, 177, 161);

}

.openFactor {
  min-height: 450px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  padding-bottom: 20px;
  transition: all 0.3s;
}

.productsFactorArea {
  background-color: #FAFAFB;
  padding: 20px;
  border-bottom: 1px solid #dbdbdb;
}

.paymentFactorArea {
  padding: 20px;
}

.titleMoreInfoItem {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: rgb(109, 90, 172);
}

.titleProduct {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: rgb(0, 0, 0);
}

.productFactor {
  /*margin-bottom: -20px;*/
}

.loadFactorBtn {
  width: 122px !important;
  height: 30px !important;
  border-radius: 10px;
}
</style>